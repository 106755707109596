import React from "react"
import { Link } from "gatsby"
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../components/layout';
import Footer from '../components/footer';
import "../components/blockquote.css";
import Img from 'gatsby-image';
import PhoneIcon from '@material-ui/icons/PhoneInTalk';
import NavigateNext from '@material-ui/icons/ArrowForward';
import NavigateBefore from '@material-ui/icons/ArrowBack';
import SEO from '../components/seo';

const styles = theme => ({
  gridContainer: {
    width: "100%",
    marginTop: "56px",
    minHeight: "600px",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
    height: "550px",
    minHeight:"550px"
    },
  },
  gridContainerSecond: {
    width: "100%",
    height: "auto",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(to bottom, #fff, #4A6476)",

  },
  gridContainerThird: {
    width: "100%",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(to bottom, #1d282f, #4A6476)",
    zIndex: 5,
  },
  mainImageWithAnimation: {
    objectFit: "cover",
    width: "100%",
    maxHeight: "600px",
    height: "auto",
    opacity: 1,
    animationName: "fadeInOpacity",
  	animationIterationCount: 1,
  	animationTimingFunction: "ease-in",
  	animationDuration: "30s",
    [theme.breakpoints.down('sm')]: {
      height: "550px",
      maxHeight:"550px",
    },
    transformOrigin: "left top"
  },
  headshot: {
    width: "150px",
    height: "150px",
    borderRadius: 100,
    [theme.breakpoints.down('sm')]: {
      width: "80px",
      height: "80px",
    },
  },
  imageContainer: {
    display: "flex",
    width: "100%",
    position: "relative",
    overflow: "hidden"

  },
  overlay: {
    position: "absolute",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",
    top:0,
    left: 0,
    background: 'linear-gradient(to bottom, rgba(0,0,0,.4) 0%, rgba(0,0,0,.5) 15%, rgba(0,0,0,.5) 25%, rgba(0,0,0,.6) 50%, rgba(0,0,0,.5) 75%, rgba(0,0,0,.5) 85%, rgba(0,0,0,.4) 100% )',
    width: "100%",
    height: "100%",
    zIndex: 2,
    overflow:"hidden",
  
  },
  contentContainer: {
    display: "flex",
    maxWidth: "1200px",
    height: "auto",
    direction: 'row',
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
  contentContainer2: {
    display: "flex",
    maxWidth: "1200px",
    height: "auto",
    direction: 'row',
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
    // animationName: "fadeInOpacity3",
  	// animationIterationCount: 1,
  	// animationTimingFunction: "ease-in",
  	// animationDuration: "1s",
  },
  practiceContainer: {
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "12px",
      marginRight: "12px",
    },
    width: "100%"
  },
  headline1: {
    marginTop: "50px",
    color: "#fff",
    zIndex:5,
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: "22px",
      marginTop: "40px",
    },
  },
  headline2: {
    color: "#fff",
    zIndex:5,
    width: "100%",
    textAlign: "justify",
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
      //marginBottom: "30px",
    },
    '& a': {
      textDecoration: 'none',
      color: "#fff",
    },
  },
  phone: {
    color: "#fff",
    zIndex:5,
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      fontSize: "16px",
      //marginBottom: "30px",
    },
    '& a': {
      textDecoration: 'none',
      color: "#fff",
    },
    
  },
  practiceArea: {
    padding: "20px",
    color: "#000",
    zIndex:5,
    width: "100%",
    textAlign: "center",
  },
  testimonialItem: {
    height: "auto",
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    direction: "column",
    minHeight: "auto",
    opacity: 1,
  	animationName: "fadeInOpacity2",
  	animationIterationCount: 1,
  	animationTimingFunction: "fade-in",
  	animationDuration: "3s",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "12px",
      marginRight: "12px",
    },
  },
  testimonials: {
    paddingTop: "20px",
    color: "#fff",
    zIndex:5,
    width: "100%",
    textAlign: "center",
  },
  testimonialHeading: {
    color: "#fff",
  },

  practiceCard: {
    background: "linear-gradient(to bottom, #1d282f, #4A6476)",
  },
  practiceCardTitle: {
    color: "#fff",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "5px",
    margin: "0 auto"
  },
  media: {
    height: "150px",
    filter: "grayscale(90%)"
  },
  link: {
    textDecoration: "none",
  },
  block: {
    color: "#fff"
  },
  blockCite: {
    marginTop: "5px",
    float: "right"
  },
  icon: {
    fontSize: "24px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "13px",
    },
  },
  practiceImageContainer : {
    width: "100%",
    height: "200px",
    minHeight: "200px",
    
  },
  practiceImage: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    borderRadius: "5px 5px 0px 0px",
    filter: "grayscale(90%)",
    WebkitFilter: "grayscale(90%)"
  },
  practiceAreaTitleContainer: {
    width: "100%",
    height: "auto",
    background:  "linear-gradient(to bottom, #1d282f, #4A6476)",
    borderRadius: "0px 0px 5px 5px ",
    
  },
  testArrowNext: {
    cursor: "pointer",
    color: "#fff",
    marginBottom: "5px",
    marginLeft: "15px"
  },
  testArrowBefore: {
    cursor: "pointer",
    color: "#fff",
    marginBottom: "5px",
    marginRight: "10px"
  }
});



class IndexPage extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        slides: [this.props.data.bannerImage1.childImageSharp.fluid, this.props.data.bannerImage2.childImageSharp.fluid, this.props.data.bannerImage3.childImageSharp.fluid ],
        myIndex: 0,
        slide: this.props.data.bannerImage1.childImageSharp.fluid,
        imageKey: 0,
        testKey: 0,
        myTestIndex: 0,
        testimonial: this.props.data.allContentfulTestimonials.edges[0].node,
        testimonials: this.props.data.allContentfulTestimonials.edges,
      };
  }



  componentDidMount(){
    this.setInterval = setInterval(this.slideShow.bind(this), 30000);
    this.setTestInterval = setInterval(this.testimonialSlides.bind(this), 12000);
  }

  componentWillUnmount(){
    clearInterval(this.setInterval);
    clearInterval(this.setTestInterval);
  }

  slideShow(){

    let myIndex = this.state.myIndex;

    if ((myIndex + 1 ) > this.state.slides.length - 1){
      myIndex = 0;
    } else {
      myIndex = myIndex + 1;
    }
    let imageKey = this.state.slides[myIndex].src
    console.log(imageKey)
    this.setState({
      slide: this.state.slides[myIndex],
      myIndex,
      imageKey,
    });

  }
  testimonialSlides(){
    let myTestIndex = this.state.myTestIndex;
    if ((myTestIndex + 1 ) > this.state.testimonials.length - 1){
      myTestIndex = 0;
    } else {
      myTestIndex = myTestIndex + 1;
    }
    let testKey = myTestIndex;
    this.setState({
      testimonial: this.state.testimonials[myTestIndex].node,
      myTestIndex,
      testKey,
    });
  }

  previousTestimonial(){
    clearInterval(this.setTestInterval);
    let myTestIndex = this.state.myTestIndex;
    if ((myTestIndex - 1 ) < 0){
      myTestIndex = this.state.testimonials.length - 1;
    } else {
      myTestIndex = myTestIndex - 1;
    }
    let testKey = myTestIndex;
    this.setState({
      testimonial: this.state.testimonials[myTestIndex].node,
      myTestIndex,
      testKey,
    });
  }


  nextTestimonial(){
    clearInterval(this.setTestInterval);
    let myTestIndex = this.state.myTestIndex;
    if ((myTestIndex + 1 ) > this.state.testimonials.length - 1){
      myTestIndex = 0;
    } else {
      myTestIndex = myTestIndex + 1;
    }
    let testKey = myTestIndex;
    this.setState({
      testimonial: this.state.testimonials[myTestIndex].node,
      myTestIndex,
      testKey,
    });
  }


  renderPracticeAreaCards(){
    const { data, classes } = this.props;
    let practiceAreas = data.allContentfulPracticeAreas.edges;
    return (
      practiceAreas.map((practiceArea, index) => {
        let title = practiceArea.node.title;
        let key = `home${index}${practiceArea.node.id}`;
        let image = `${practiceArea.node.image.file.url}?w=500&q=50`;
        let slug = practiceArea.node.slug;
        return (

          <Grid  container key={key} justify= "center" item xs={12} md={6} >
            <Link  className={classes.link} to={`/${slug}`}>
              <Grid className = {classes.practiceImageContainer} item xs={12}>
                <img alt={image} className= {classes.practiceImage} src={image}/>
              </Grid>
              <Grid className={classes.practiceAreaTitleContainer} item xs={12}>
              <Typography className={classes.practiceCardTitle} gutterBottom variant="h6" component="h2">
                {title}
              </Typography> 
              </Grid>
            </Link>
          </Grid>


        );
      })
    );
  }


  render(){
    const {classes, data } = this.props;
    let slide = this.state.slide;
    let test = this.state.testimonial;
    return (
      <Layout>
        <SEO pagePath ="/"  pageTitle = "Home" keywords = "Attorney, CPA, Cook County, Lake County, McHenry County, Kane County and DuPage County, Family Law, Real Estate, Bankruptcy, Business Litigation and General Practice " pageDescription = {data.allContentfulCarousel.edges[0].node.subHeading.subHeading} image = {data.shareImage.childImageSharp.resize.src} imageHeight="400" imageWidth="400"/>
        <Grid className = {classes.gridContainer} container  >
          <Grid className= {classes.imageContainer} container>
            <Img key = {this.state.imageKey} className={classes.mainImageWithAnimation} fluid = {slide}/>
            <Grid container className={classes.overlay}>
              <Grid container className={classes.contentContainer2} spacing={24}>
                <Grid item xs={12} >
                  <Typography className={classes.headline1} variant="h3" >
                    {data.allContentfulCarousel.edges[0].node.headline}
                  </Typography>
                </Grid>
                <Grid item align="center" xs={12} >
                <Img className={classes.headshot} fluid = {this.props.data.headshot.childImageSharp.fluid}/>
                </Grid>
                
                <Grid item  xs={12} >
                  <Typography className={classes.headline2} variant="h5">
                    {data.allContentfulCarousel.edges[0].node.subHeading.subHeading}
                  </Typography>
                </Grid>
                <Grid item  xs={12} >
                  <Typography className={classes.phone} variant="h4">
                    <PhoneIcon className={classes.icon} /> <a href="tel:8479916675">847-991-6675</a>
                  </Typography>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          </Grid>

          <Grid className = {classes.gridContainerSecond} container>
            <Grid container className={classes.contentContainer} spacing={24}>
              <Grid item xs={12} >
                <Typography className={classes.practiceArea} variant="h4" >
                  Areas of Practice
                </Typography>
              </Grid>
              <Grid className={classes.practiceContainer} container spacing={24} >

                {this.renderPracticeAreaCards()}
              </Grid>
            </Grid>
          </Grid>

          <Grid className = {classes.gridContainerThird} container>
            <Grid container className={classes.contentContainer} spacing={24}>
              <Grid item xs={12} >
                <Typography className={classes.testimonials} variant="h4" >
                  Testimonials
                </Typography>
              </Grid>
              <Grid container item xs={12} >
                <Grid container justify="flex-end" item xs={6}><NavigateBefore onClick={this.previousTestimonial.bind(this)} className={classes.testArrowBefore}></NavigateBefore></Grid>
                <Grid container justify="flex-start" item xs={6}><NavigateNext onClick={this.nextTestimonial.bind(this)} className={classes.testArrowNext} ></NavigateNext></Grid>
                
              </Grid> 
              <Grid className={classes.practiceContainer} container spacing={24} >
                <Grid key = {this.state.testKey} className={classes.testimonialItem} container item xs={12} md={8}>
                  <Typography className={classes.testimonialHeading} variant="subheading">
                    {test.header}
                  </Typography>
                  <Typography color="textPrimary" variant="subheading">
                    <blockquote className = {classes.block}>
                      "{test.text.text}"
                      <footer>
                          <cite className={classes.blockCite}>
                              {test.authorAndDate}
                          </cite>
                      </footer>
                    </blockquote>
                  </Typography>
                </Grid>
                

              </Grid>
            </Grid>
          </Grid>

          <Footer/>
      </Layout>
    );
  }

}

export default withStyles(styles)(IndexPage);

export const pageQuery = graphql`
  query {
    allContentfulCarousel {
      edges {
        node {
          id
          headline
          subHeading {
            id
            subHeading
          }
        }
      }
    }
    allContentfulTestimonials{
      edges {
        node {
          id
          header
          text {
            id
            text
          }
          authorAndDate
        }
      }
    }
    allContentfulPracticeAreas{
      edges {
        node {
          id
          title
          slug
          image {
            id
            file {
              url
            }

          }
        }
      }
    }
    bannerImage1: file(relativePath: {eq: "courthouse.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bannerImage2: file(relativePath: {eq: "chicago.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bannerImage3: file(relativePath: {eq: "bookcase.jpg"}) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bannerImage4: file(relativePath: {eq: "chess.jpg"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    headshot: file(relativePath: {eq: "Kaiser.jpg"}) {
      childImageSharp {
        fluid (maxWidth: 100){
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    shareImage: file(relativePath: {eq: "Kaiser.jpg"}) {
      childImageSharp {
        resize(width: 400, height: 400) {
          src
        }
      }
    }
    
  }
`
